.main {
    max-width: var(--main-page-width);
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--footer-height);
}

.main-mobile {
    font-size: 9pt;
}
