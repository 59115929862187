:root {
    --color-dirty-white: #f4f4f4;
    --color-white: #fff;

    --box-shadow-light: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    --box-shadow-strong: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    --menu-height: 25px;
    --footer-height: 20px;
    --main-page-width: 800px;
}

html {
    scrollbar-gutter: stable;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.clickable {
    cursor: pointer;
}

.list-table {
    padding: 10px;
    width: 100vw;
    border-collapse: collapse;
}

.list-table th {
    text-align: left;
    border: 1px solid lightgray;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
}

.list-table-desktop td {
    border-bottom: 1px solid lightgray;
}

.list-table-mobile td {
    border: 1px solid lightgray;
}

.list-table td,
.list-table th {
    max-width: 200px;
}

.list-table-desktop td,
.list-table-desktop th {
    padding: 5px 15px;
}

.list-table-desktop tbody tr:hover {
    background-color: var(--color-dirty-white);
}

.list-table-mobile td,
.list-table-mobile th {
    padding: 3px 5px;
}

.list-search {
    padding: 10px;
}
