.home {
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    gap: 16px;
    align-items: center;
}

.home-text {
    padding: 20px;
    border-radius: 15px;
}

.home-img {
    width: 600px;
    border-radius: 5px;
}

.home-img-mobile {
    width: 300px;
}

.home-text {
    border-radius: 20px;
    background-color: var(--color-dirty-white);
    box-shadow: var(--box-shadow-light);
}

.home-text__instagram-paragraph {
    font-size: 9pt;
    text-align: center;
}

.home-text__instagram-paragraph a,
.home-text__instagram-paragraph a:visited,
.home-text__instagram-paragraph a:active {
    color: #515bd4;
}
