.mount {
    font-size: small;
    font-weight: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid grey;
}

.mount-mobile {
}

.mount-desktop {
    width: 70px;
}
