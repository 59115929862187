.album-carousel {
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.album-carousel > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.album-carousel-desktop > div {
    border-radius: 5px;
    margin: 10px;
    height: 200px;
    width: 200px;
}

.album-carousel-mobile > div {
    border-radius: 3px;
    margin: 3px;
    height: 25vw;
    width: 25vw;
}
