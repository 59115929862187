.page-change {
    display: flex;
    justify-content: center;
}

.page-change > div {
    display: flex;
    justify-content: space-between;
    width: 90px;
}

.page-change > div > div {
    text-align: center;
    width: 30px;
}
