.footer {
    position: fixed;
    height: var(--footer-height);
    width: var(--main-page-width);
    bottom: 0px;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
}
