.menu-button {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
    box-shadow: var(--box-shadow-light);
    transition: all 0.3s;
}

.menu-button-desktop {
    margin: 15px;
    font-size: 16pt;
}

.menu-button:hover {
    box-shadow: var(--box-shadow-strong);
}
