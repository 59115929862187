.menu-layer {
    background-color: white;
    width: calc(100vw - 20px);
    position: sticky;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    top: 0;
    height: var(--menu-height);
    z-index: 1;
    padding: 10px;
}

.menu-left {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
}

.menu-layer .menu-button {
    /* TODO */
    /* background-color: white; */
}

.hamburger-menu-button {
    padding: 0px;
    border: 1px solid black;
    border-radius: 3px;
    height: var(--menu-height);
    min-height: var(--menu-height);
    width: var(--menu-height);
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.hamburger-menu-button > div {
    width: 20px;
    height: 3px;
    border-radius: 2px;
    background-color: black;
}
